import React, { useState, useEffect } from "react";
import "./StatusSelect.css";
import { toast } from "react-toastify";
import "react-widgets/styles.css";
import { Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Stack from "@mui/material/Stack";
import Fab from "@mui/material/Fab";
import { faBorderNone } from "@fortawesome/free-solid-svg-icons";
import { alignProperty } from "@mui/material/styles/cssUtils";

const Status = (props) => {
  const [status, setStatus] = useState(props.value);
  const [isTextarea, setIsTextarea] = useState(false);

  const handleDescriptionChange = (event) => {
    const status = event.target.value;
    setStatus(status);
  };

  useEffect(() => {
    setStatus(props.value);
  }, [props.value]);

  const handleClick = () => {
    setIsTextarea(true);
  };

  const handleBlur = () => {
    setIsTextarea(false);
  };

  const onSave = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/status`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${props.user.token}`,
        },
        body: JSON.stringify({
          statusInput: status,
          projectId: props.id,
        }),
      });
      console.log(response);
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      toast.success("Status atualizado com sucesso.");
    } catch (error) {
      console.log(error);
      toast.error("Erro ao atualizar a Status da tarefa.");
    }
  };

  return (
    <>
      <div className="saveInput">
        <div>
          <input
            placeholder="Insira um Status..."
            sx={{ justifyContent: "center" }}
            type="text"
            value={status}
            onChange={handleDescriptionChange}
            style={{
              backgroundColor: "#f1f0f0",
              width: "550px",
              fontFamily: "Poppins, sans-serif",
              fontSize: "14px",
              border: "none",
              outline: "none",
              textOverflow: "ellipsis",
              backgroundColor: "white",
              shadow: "none",
              marginTop: "10px",
              borderRadius: "200px",
            }}
          />
        </div>
        <div>
          <Fab
            title="Salvar"
            aria-label="add"
            size="small"
            onClick={onSave}
            variant="contained"
            sx={{
              backgroundColor: "transparent",
              shadow: "none",
              zIndex: 1,
              width: "20px",
              marginLeft: "10px",
              boxShadow: "none",
              color: "#181071",
              "&:hover": {
                background: "none",
              },
            }}
          >
            <SendIcon
              sx={{
                width: "10px",
              }}
            />
          </Fab>
        </div>
      </div>
    </>
  );
};
export default Status;
