import * as React from "react";
import "../components/StatusSelect.css";
import DeadlineSelect from "./DeadlineSelect";
import PriorityChange from "./PriorityChange";
import Description from "./Status";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faLeftLong } from "@fortawesome/free-solid-svg-icons";
import Status from "./Status";

export default function BasicTable(props) {
  const onSave = async (value, project) => {
    props.onSave(value, project);
  };

  const TextWithHtmlTags = ({ text }) => {
    if (!text) {
      return "";
    }
    const parsedText = text
      .replace(/\[P\]/g, "<p>")
      .replace(/\[\/P\]/g, "</p>")
      .replace(/\[TD\]/g, "<td>")
      .replace(/\[\/TD\]/g, "</td>")
      .replace(/\[TABLE\]/g, "<tb>")
      .replace(/\[\/TABLE\]/g, "</tb>")
      .replace(/\[TR\]/g, "<tr>")
      .replace(/\[\/TR\]/g, "</tr>")
      .replace(/NULL/g, "");

    return <div dangerouslySetInnerHTML={{ __html: parsedText }} />;
  };

  const formataKeywords = (keywords) => {
    const wordsArray = keywords.split(",");
    // seleciona somente as palavras que começam com "entre_"
    const entreWords = wordsArray.filter((word) => word.startsWith("entre_"));
    // remove "entre_" de cada palavra do array
    const words = entreWords.map((word) =>
      word.replace("entre_", "").toUpperCase()
    );

    let formatedKeywords = words.join("<br />");
    return <span dangerouslySetInnerHTML={{ __html: formatedKeywords }} />;
  };

  console.log("TASKS", props.tasks);
  return (
    <>
      <section class="table-wrap" style={{ alignItems: "center" }}>
        <div class="table-projetos">
          <div class="table-header ">
            <ul>
              {props.isAdmin && (
                <li class="header-item tab-col-5">
                  <p>P</p>
                </li>
              )}
              <li className={`header-item ${
                      props.isAdmin ? "tab-col-20" : "tab-col-20"
                    }`}>
                <p>Nome</p>
              </li>
              <li sx={{justifyContent:'center'}} className={`header-item prazo ${
                      props.isAdmin ? "tab-col-10" : "tab-col-15"
                    }`}>
                <p sx={{justifyContent:'center'}} >Prazo</p>
              </li>
              <li className={`header-item  ${
                      props.isAdmin ? "tab-col-30" : "tab-col-30"
                    }`}>
                <p>Descrição</p>
              </li>
              <li  className={`header-item  ${
                      props.isAdmin ? "tab-col-30" : "tab-col-30"
                    }`}>
                <p>
                  Status Resumido
                </p>
              </li>
            </ul>
          </div>
          {props.tasks.map((value) => {
            return (
              <div class="table-line" style={{ justifyContent: "flex-start" }}>
                <ul>
                  {props.isAdmin && (
                    <li class="table-item tab-col-5">
                      <PriorityChange
                        id={value.ID}
                        key={value.ID}
                        value={value.PRIORITY}
                        user={props.user}
                      />
                    </li>
                  )}
                  <li className={`table-item ${
                      props.isAdmin ? "tab-col-20" : "tab-col-20"
                    }`}>
                    <p style={{ textAlign: "left" }}>
                      {value.TITLE.replace(
                        /\[Para EntrePay\] :|\[PROJETOS\] - /g,
                        ""
                      )}
                    </p>
                  </li>

                  <li className={`table-item prazo ${
                      props.isAdmin ? "tab-col-10" : "tab-col-15"
                    }`}>
                    {value.DEADLINE.split(" ")[0]
                      .split("-")
                      .reverse()
                      .join("/")}
                  </li>

                  <li
                    className={`table-item ${
                      props.isAdmin ? "tab-col-30" : "tab-col-30"
                    }`}
                  >
                    <p className="descripton-text clamp-2">
                      <TextWithHtmlTags text={value.DESCRIPTION} />
                    </p>
                    {value.DESCRIPTION && (
                      <span className="description-tooltips">
                        <TextWithHtmlTags text={value.DESCRIPTION} />
                      </span>
                    )}
                  </li>

                  {props.isAdmin ? (
                    <li class="table-item tab-col-30">
                      <Status
                        id={value.ID}
                        key={value.ID}
                        value={value.INPUT_STATUS}
                        user={props.user}
                      />
                    </li>
                  ) : (
                    <li class="table-item tab-col-30">
                      <p
                        sx={{
                          width: "550px",
                        }}
                      >
                        {value.INPUT_STATUS}
                      </p>
                    </li>
                  )}
                </ul>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
}
