import { Button } from '@mui/material';
import React from 'react'

export default function Header(props) {
    return(
        <>
            <header>
                <div className="entre-header">
                    <div className="entre-header-wrap">
                      <div style={{width: "94%", display: "flex", justifyContent: "space-between"}}>
                          <div className="logo-wrap" style={{margin: "0 auto", paddingBottom:'10px'}}>
                              <img  src="/images/logo-full-entrepay.png" alt="logo-Entre" title="EntrePay Logo" />
                              <span sx={{paddingTop: '100px'}}>INCIDENTES DO CREDENCIAMENTO</span>
                          </div>
                          <div style={{alignSelf: "center"}}>
                              {!!props.user && !!props.user.id_user ?
                                      <Button
                                        type="submit"
                                        variant="outlined"
                                        onClick={() => props.onLogout()}
                                        sx={{
                                            width: 120,
                                            alignSelf: "center",
                                            backgroundImage: `linear-gradient(180deg, #0048FA 0%, #1C2439 100%)`,
                                            color: "#fff",
                                            fontWeight: "bold",
                                            borderRadius: 20,
                                            border: '1px solid #AFACAC',
                                            "&:hover": {
                                                color: "white",
                                            },
                                        }}
                                      >
                                          Sair
                                      </Button>
                                :
                                <Button
                                    type="submit"
                                    variant="outlined"
                                    onClick={() => window.location.href = "/admin"}
                                    sx={{
                                        width: 120,
                                        alignSelf: "center",
                                        backgroundImage: `linear-gradient(180deg, #0048FA 0%, #1C2439 100%)`,
                                        color: "#fff",
                                        fontWeight: "bold",
                                        borderRadius: 20,
                                        border: '1px solid #AFACAC',
                                        "&:hover": {
                                            color: "white",
                                        },
                                    }}
                                  >
                                      Admin Area
                                  </Button>
                                }
                          </div>
                      </div>
                    </div>
                </div>
            </header>
           
        </>
    )
}