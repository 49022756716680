import * as React from "react";
import { toast } from "react-toastify";
import BasicTable from "../components/BasicTable";
import Header from "../components/Header";
import { adminId } from "../constants";

export default function Home(props) {
  const [projects, setProjects] = React.useState([]);
  const [allProjects, setAllProjects] = React.useState([]);
  const [project, setProject] = React.useState("todos");
  const [projectName, setProjectName] = React.useState("");
  const [vertical, setVertical] = React.useState("todas");
  const [estado, setEstado] = React.useState("ativo");
  const [statusColor, setStatusColor] = React.useState("todos");
  const [deadline, setDeadline] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [reload, setReload] = React.useState(0);
  const [tasks, setTasks] = React.useState([]);
  const { onLogout } = props;

  

  

  const getTasks = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/tasks`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok === false) {
        throw new Error(response.statusText);
      }

      const allTasks = await response.json();
      setTasks(allTasks);
    } catch (error) {
      toast.error("Erro ao recarregar projetos.");
    }
  };

  React.useEffect(() => {
    getTasks();
    
  }, []);


  return (
    <>
      <Header  onLogout={props.onLogout} user={props.user}/>

     
      <BasicTable
        projects={projects}
        isAdmin={!!props.user && props.user.id_user === adminId}
        user={props.user}
        tasks= {tasks}
      />
    </>
  );
}
