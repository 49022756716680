import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import React, { useState } from 'react';
import './App.css'
import { adminId } from "./constants";
import './css/main.css'
import './css/header.css'
import './css/breadcrumbs.css'
import './css/table.css'
import './css/filter-search.css'

function App() {
  const userData = localStorage.getItem("user");
  const [user, setUser] = React.useState(
    userData ? JSON.parse(userData) : null);

  const onLogin = (user) => {
    setUser(user);
  };

  const onLogout = () => {
    localStorage.removeItem("user");
    setUser(null);
  };

  return (
    <BrowserRouter>
      <Routes>
        
     
        <Route path='/admin'
          element={
            user ? 
               user.id_user === adminId ?
                <Home user={user} onLogout={onLogout} /> : <Login onLogin={onLogin} />
            :
            <Login onLogin={onLogin} />
          } 
        />
       
       <Route path='/'
          element={
            <Home/>
          } 
        />
       

     
      </Routes>
    </BrowserRouter>
  );
}
export default App;